<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.rouletteTickets') }}</h5>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            <label>{{ $t('sportsbets.startDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            <label>{{ $t('sportsbets.endDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="shopsModel" :options="shops" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.shop') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="statusModel" :options="ticketStatuses" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.status') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-4 p-text-right"><Button :label="$t('buttons.getData')" class="p-button-raised p-button-success" @click="applyFiltersOnly()" /></div>
                </div>
                <Fieldset :legend="$t('search.byId')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-2">
                            <span class="p-float-label">
                                <InputText style="width: 100%" type="text" v-model="ticketSerial" />
                                <label>{{ $t('bets.ticketSerial') }}</label>
                            </span>
                        </div>
                        <div class="p-col-12 p-md-2 p-text-right"><Button :label="$t('buttons.searchData')" class="p-button-raised p-button-warning" @click="applySerialOnly()" /></div>
                    </div>
                </Fieldset>
                <DataTable
                    :lazy="true"
                    :value="items"
                    :paginator="true"
                    :rows="perPage"
                    :loading="loading"
                    class="p-mt-3"
                    :totalRecords="totalPages"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    ref="datatable"
                    responsiveLayout="scroll"
                    :paginatorBottom="true"
                    v-model:expandedRows="expandedRows"
                >
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column :expander="true" headerStyle="width: 3rem" />
                    <Column :header="$t('general.status')">
                        <template #body="{ data }">
                            <Button :label="$t('bets.status.' + getTicketStatusName(data.status))" :class="'p-button-outlined p-mr-2 p-mb-2 p-button-' + getTicketStatusColor(data.status) + ' p-mr-2'" />
                        </template>
                    </Column>
                    <Column field="ticketSerial" :header="$t('bets.ticketSerial')" :sortable="true"></Column>
                    <Column field="ticketDate" :header="$t('bets.placeDate')" :sortable="true">
                        <template #body="{ data }"> {{ formatCSDateAndTime(data.ticketDate) }} </template>
                    </Column>
                    <Column field="shopId" :header="$t('bets.shopName')">
                        <template #body="{ data }">{{ shopsDictionary[data.shopId] }}</template>
                    </Column>
                    <Column field="roundNo" :header="$t('virtuals.roundNo')" :sortable="true"></Column>

                    <Column field="playedAmount" :header="$t('bets.playedAmount')" :sortable="true">
                        <template #body="{ data }"> {{ formatCurrency(data.playedAmount) }} </template>
                    </Column>
                    <Column field="stakeAmount" :header="$t('bets.stakeAmount')" :sortable="true">
                        <template #body="{ data }"> {{ formatCurrency(data.stakeAmount) }} </template>
                    </Column>
                    <Column field="winInfo" :header="$t('bets.winAmount')" :sortable="true">
                        <template #body="{ data }"> {{ notNullOrEmptyObject(data.winInfo) ? formatCurrency(data.winInfo.winAmount) : 0 }} </template>
                    </Column>
                    <Column field="jackpotInfo" :header="$t('bets.jackpotAmount')" :sortable="true">
                        <template #body="{ data }"> {{ notNullOrEmptyObject(data.jackpotInfo) ? formatCurrency(data.jackpotInfo.jackpotValue) : 0 }} </template>
                    </Column>
                    <Column field="winInfo" :header="$t('bets.winnerId')">
                        <template #body="{ data }"> {{ notNullOrEmptyObject(data.winInfo) ? data.winInfo.winnerSSN : '' }} </template>
                    </Column>
                    <Column field="winInfo" :header="$t('bets.winnerName')">
                        <template #body="{ data }"> {{ notNullOrEmptyObject(data.winInfo) ? data.winInfo.winnerName : '' }} </template>
                    </Column>

                    <Column field="payInfo" :header="$t('bets.payDate')">
                        <template #body="{ data }"> {{ notNullOrEmptyObject(data.payInfo) ? formatCSDateAndTime(data.payInfo.date) : '' }} </template>
                    </Column>
                    <Column field="payInfo" :header="$t('bets.paidBy')">
                        <template #body="{ data }"> {{ notNullOrEmptyObject(data.payInfo) ? getPaidBy(data.payInfo.operatorId) : '' }} </template>
                    </Column>
                    <Column field="stornoInfo" :header="$t('bets.voidDate')">
                        <template #body="{ data }"> {{ notNullOrEmptyObject(data.stornoInfo) ? formatCSDateAndTime(data.stornoInfo.date) : '' }} </template>
                    </Column>
                    <Column field="stornoInfo" :header="$t('bets.voidBy')">
                        <template #body="{ data }"> {{ notNullOrEmptyObject(data.stornoInfo) ? getPaidBy(data.stornoInfo.operatorId) : '' }} </template>
                    </Column>
                    <template #expansion="slotProps">
                        <VirtualsTicketDetails :ticketSerial="slotProps.data.ticketSerial"></VirtualsTicketDetails>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { BoTicketsApi } from '../../service/BoTicketsApi';
import { BoApi } from '../../service/BoApi';
import VirtualsTicketDetails from './Components/VirtualsTicketDetails.vue';

import settings from '../../settings/generalSettings';
import { uiSettings } from '../../settings/uiSettings';
export default {
    name: 'rouletteTickets',
    components: {
        VirtualsTicketDetails,
    },
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            shopsDictionary: [],
            cashiersDictionary: [],
            startDateModel: null,
            endDateModel: null,
            BoTicketsApi: new BoTicketsApi(),
            BoApi: new BoApi(),
            ticketStatuses: settings.TicketStatuses,
            statusModel: { name: 'All', value: 100 },
            shops: [],
            users: [],
            items: [],
            shopsModel: { name: 'All', id: '0' },
            ticketType: settings.ticketType.roulette,
            sort: 'ticketDate|desc',
            crtPage: 0,
            perPage: uiSettings.tableRows,
            loading: true,
            totalPages: 0,
            ticketSerial: '',
            expandedRows: [],
        };
    },
    props: {
        drawIdParam: {
            type: String,
            default: '',
        },
    },
    watch: {
        shopsModel() {
            if (!this.notNullOrEmptyObject(this.shopsModel)) {
                this.shopsModel = { name: 'All', id: '0' };
            }
        },
        statusModel() {
            if (!this.notNullOrEmptyObject(this.statusModel)) {
                this.statusModel = { name: 'All', value: 100 };
            }
        },
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day + 'T00:00';
        var endDateString = year + '-' + month + '-' + day + 'T23:59';
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);
        this.selectedDrawId = this.drawIdParam;
        this.getShops();
        this.getCashierUsers();
        this.readItems();
    },
    methods: {
        getPaidBy(cashierId) {
            return cashierId != null ? this.cashiersDictionary[cashierId] : this.$t('system.terminal');
        },
        getCashierUsers() {
            this.BoApi.getAllUsers()
                .then((userResponse) => {
                    this.users = userResponse.data;
                    this.createcashiersDictionary();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        createcashiersDictionary() {
            for (let i = 0; i < this.users.length; i++) {
                this.cashiersDictionary[this.users[i]._id] = this.users[i].username;
            }
        },
        getShops() {
            this.BoApi.getShops('')
                .then((shopsResponse) => {
                    let shopsDdlValues = [];
                    for (var i = 0; i < shopsResponse.data.length; i++) {
                        let shopItem = {
                            name: shopsResponse.data[i].code + ' - ' + shopsResponse.data[i].name,
                            id: shopsResponse.data[i]._id,
                        };
                        shopsDdlValues.push(shopItem);
                    }
                    this.shops = shopsDdlValues;
                    this.createShopsDictionary();
                    this.shops.unshift(this.shopsModel);
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        createShopsDictionary() {
            for (let i = 0; i < this.shops.length; i++) {
                this.shopsDictionary[this.shops[i].id] = this.shops[i].name;
            }
        },
        applyFiltersOnly() {
            this.ticketSerial = '';
            this.readItems();
        },
        applySerialOnly() {
            this.shopsModel = { name: 'All', id: '0' };
            this.statusModel = { name: 'All', value: '100' };
            this.readItems();
        },
        onPage(event) {
            this.readItems(event.page + 1);
        },
        onSort(event) {
            let sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            if (event.sortField == 'winInfo') {
                this.sort = 'winAmount' + '|' + sortOrder;
            } else {
                this.sort = event.sortField + '|' + sortOrder;
            }
            this.readItems();
        },
        readItems(page = 1) {
            this.crtPage = page;
            this.loading = true;

            const params = {
                per_page: this.perPage,
                page: page,
                sort: this.sort,
                startDate: this.formatApiDate(this.startDateModel),
                endDate: this.formatApiDate(this.endDateModel),
                shop: this.shopsModel.id,
                status: this.statusModel.value,
                ticketSerial: this.ticketSerial,
                ticketType: this.ticketType,
            };
            this.BoTicketsApi.getTickets(params)
                .then((response) => {
                    if (this.notNullOrEmptyObject(response.data)) {
                        this.items = response.data.data;
                        this.totalPages = response.data.total;
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.checkForUnauthorizedError(error);
                });
        },
    },
};
</script>